<i18n locale="th" lang="yaml" >
company.address : "ที่อยู่"
company.billing_name : "ชื่อจดทะเบียน"
company.billing_address : "ที่อยู่ในการออกบิล"
company.juristic_no : "เลขที่นิติบุคคล"
company.abbr_name : "ตัวย่อ"
</i18n>

<template>
<a-card
	:bordered="false" class="company-card mycard mycard-with-details"
	:loading="loading">
	<a-button
		v-if="canUpdate"
		class="mycard-button-action"
		shape="circle"
		icon="edit"
		@click="handleUpdateClick" />

	<div class="mycard-content">
		<div class="mycard-header">
			<ImageLoader
				class="mycard-image"
				:src="company.image_url"
				size="large"
				border-type="round">
				<a-icon type="shop" />
			</ImageLoader>
			<div class="title">{{company.name}}</div>
			<div class="subtitle">{{$tenum('company_type',`${company.company_type}`)}}</div>
		</div>
		<ul class="mycard-details">
			<li>
				<span class="title text-bold">{{$t('company.juristic_no')}}</span> : {{company.juristic_no | emptyCheck}}
			</li>
			<li>
				<span class="title text-bold">{{$t('company.abbr_name')}}</span> : {{company.abbr_name | emptyCheck}}
			</li>

			<li>
				<a-icon type="shop" :title="$t('company.billing_name')"/>
				<span>
					{{company.billing_name}}
				</span>
			</li>



			<li>
				<a-icon type="home" />
				<span class="title">{{$t('company.address')}}</span>
				<div v-trim class="subdetail multiline">
					{{addressDisplay}}
				</div>
			</li>
			<li>
				<a-icon type="home" />
				<span class="title">{{$t('company.billing_address')}}</span>
				<div v-trim class="subdetail multiline">
					{{company.billing_address}}
				</div>
			</li>
			<li>
				<PhoneLink :value="company.contact_phone" />
			</li>
			<li>
				<EmailLink :value="company.contact_email" />
			</li>
		</ul>
	</div>
</a-card>
</template>

<script>
import ImageLoader from "@components/common/ImageLoader.vue"
import EmailLink from "@components/common/EmailLink.vue"
import PhoneLink from "@components/common/PhoneLink.vue"
import {addressDisplay} from "@utils/stringUtil.js"

export default {
	components : {
		ImageLoader , EmailLink, PhoneLink,
	} ,
	props : {
		loading : {
			type : Boolean,
			default: false
		} ,
		company : {
			type : null,
			default : () => []
		} ,
		showUpdate : {
			type: Boolean,
			default: false
		}
	} ,
	computed : {
		addressDisplay() {
			return addressDisplay(this,this.company.address,this.company,this.$t('common.unspecify'))
		} ,
		canUpdate() {
			return this.showUpdate && this.company.id && this.$authorize('update','company',{companyId:this.company.id})
		}
	} ,
	methods : {
		handleUpdateClick() {
			this.$open({
					name : 'company/update',
					params : {id : this.company.id}
				});
		} ,
	}
}
</script>

<style lang="less" scoped>
.company-card {
	min-height: 500px;
}
</style>
